<template>
  <page-header />
  <page-form
    :creative-image="creative.image"
    :creative-heading="creative.heading"
    :creative-text="creative.text"
    :creative-tcpa="creative.tcpa"
    @viewPartners="viewPartners = true"/>
  <description />
  <features />
  <call-to-action />
  <page-footer />
  <partner-list v-if="viewPartners" @closePartners="viewPartners = false" />
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import PageForm from "@/components/PageForm";
import Description from "@/components/Description";
import Features from "@/components/Features";
import CallToAction from "@/components/CallToAction";
import PartnerList from "@/components/PartnerList";

export default {
  components: {
    PageHeader,
    PageFooter,
    PageForm,
    Description,
    Features,
    CallToAction,
    PartnerList,
  },

  data() {
    return {
      viewPartners: false,
      creative: {
        text: null,
        heading: null,
        image: null,
        tcpa: null,
      },
    };
  },

  created() {
    fetch(`${process.env.VUE_APP_API_URL}/campaigns/${this.$route.params.uuid}/creative`)
      .then(response => response.json())
      .then(campaign => {
        this.creative.text = campaign.custom_creative.content;
        this.creative.heading = campaign.custom_creative.heading;
        this.creative.image = campaign.custom_creative.image_url;
        this.creative.tcpa = campaign.custom_creative.tcpa;
      });
  },
};
</script>
