<template>
  <div class="bg-cover bg-center-top" :style="`background-image: url(${require('../images/background.jpg')})`">
    <div class="flex items-center justify-center px-5 pb-5 bg-center bg-cover min-w-screen md:pt-10">
      <div class="w-full overflow-hidden text-gray-500 bg-gray-100 shadow-xl" style="max-width:1000px">
        <div class="w-full md:flex">
          <div class="hidden w-1/2 bg-scroll bg-right bg-cover md:block" :style="`background-position-x: center; background-image: url(https://images.unsplash.com/photo-1545603343-8356d34ed7bb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80)`"></div>

          <div class="w-full px-5 py-10 md:w-1/2 md:px-10">
            <div class="mb-5 text-center">
              <div class="flex justify-center pb-5 mx-auto" v-if="creativeImage">
                <img :src="creativeImage" :alt="creativeHeading" />
              </div>

              <h3 class="text-3xl font-bold text-blue-800">REGISTER</h3>
              <h2 class="text-2xl text-blue-800">{{ formattedCreativeHeading }}</h2>
              <div class="max-w-3xl pt-3 mx-auto text-center" v-if="creativeText">
                <p class="text-gray-600" v-text="creativeText"></p>
              </div>
            </div>

            <form id="register" autocomplete="off">
              <div class="flex -mx-3">
                <div class="w-1/2 px-3 mb-2">
                  <label for="first-name" class="px-1 text-xs font-semibold text-blue-800">First name*</label>

                  <div class="flex">
                    <input v-model="firstName" type="text" id="first-name" name="first-name" class="w-full py-2 pl-2 pr-3 border-2 border-gray-200 outline-none input focus:border-blue-800" placeholder="Mia" required autocomplete="false">
                  </div>
                </div>

                <div class="w-1/2 px-3 mb-2">
                  <label for="last-name" class="px-1 text-xs font-semibold text-blue-800">Last name*</label>

                  <div class="flex">
                    <input v-model="lastName" type="text" id="last-name" name="last-name" class="w-full py-2 pl-2 pr-3 border-2 border-gray-200 outline-none focus:border-blue-800" placeholder="Smith" required autocomplete="false">
                  </div>
                </div>
              </div>

              <div class="flex -mx-3">
                <div class="w-full px-3 mb-2">
                  <label for="email-address" class="px-1 text-xs font-semibold text-blue-800">Email*</label>

                  <div class="flex">
                    <input v-model="email" type="text" id="email-address" name="email-address" class="w-full py-2 pl-2 pr-3 border-2 border-gray-200 outline-none focus:border-blue-800" placeholder="miasmith@essentialhealthinfo.com" required autocomplete="false">
                  </div>
                </div>
              </div>

              <div class="flex -mx-3">
                <div class="w-full px-3 mb-2">
                  <label for="address1" class="px-1 text-xs font-semibold text-blue-800">Street Address</label>

                  <div class="flex">
                    <input v-model="address" type="text" id="address1" name="address1" class="w-full py-2 pl-2 pr-3 border-2 border-gray-200 outline-none focus:border-blue-800" placeholder="737 Walnutwood Avenue" autocomplete="false">
                  </div>
                </div>
              </div>

              <div class="flex -mx-3">
                <div class="w-full px-3 mb-2">
                  <label for="city" class="px-1 text-xs font-semibold text-blue-800">City</label>

                  <div class="flex">
                    <input v-model="city" type="text" id="city" name="city" class="w-full py-2 pl-2 pr-3 border-2 border-gray-200 outline-none focus:border-blue-800" placeholder="Chelmsford" autocomplete="false">
                  </div>
                </div>
              </div>

              <div class="flex -mx-3">
                <div class="w-full px-3 mb-2">
                  <label for="state" class="px-1 text-xs font-semibold text-blue-800">State</label>

                  <div class="flex">
                    <input v-model="state" type="text" id="state" name="state" class="w-full py-2 pl-2 pr-3 border-2 border-gray-200 outline-none focus:border-blue-800" placeholder="California" autocomplete="false">
                  </div>
                </div>
              </div>

              <div class="flex -mx-3">
                <div class="w-full px-3 mb-2">
                  <label for="zipcode" class="px-1 text-xs font-semibold text-blue-800">Zip Code</label>

                  <div class="flex">
                    <input v-model="zipCode" type="text" id="zipcode" name="zipcode" class="w-full py-2 pl-2 pr-3 border-2 border-gray-200 outline-none focus:border-blue-800" placeholder="90210" autocomplete="false">
                  </div>
                </div>
              </div>

              <div class="flex -mx-3">
                <div class="w-full px-3 mb-2">
                  <label for="phonenumber" class="px-1 text-xs font-semibold text-blue-800">Phone Number</label>

                  <div class="flex">
                    <input v-model="phone" type="text" id="phonenumber" name="phonenumber" class="w-full py-2 pl-2 pr-3 border-2 border-gray-200 outline-none focus:border-blue-800" placeholder="(519) 555-0148" autocomplete="false">
                  </div>
                </div>
              </div>

              <div class="flex -mx-3">
                <div class="w-full px-3 mb-5">
                  <label for="dob" class="px-1 text-xs font-semibold text-blue-800">Date of Birth</label>

                  <div class="flex">
                    <input v-model="dob" type="text" id="dob" name="dob" class="w-full py-2 pl-2 pr-3 border-2 border-gray-200 outline-none focus:border-blue-800" placeholder="mm/dd/yyyy" autocomplete="false">
                  </div>
                </div>
              </div>

              <div class="flex -mx-3">
                <div class="w-full px-3 mb-5">
                  <button @click.prevent="submit" class="block w-full max-w-xs px-3 py-3 mx-auto mt-4 mb-5 font-semibold text-white bg-red-600 register hover:bg-red-700 focus:bg-red-700">REGISTER</button>

                  <small>
                    <label>
                      <input type="hidden" id="leadid_tcpa_disclosure" />

                      <span v-html="creativeTcpa"></span>

                      By signing up to this EssentialHealth property, you agree to us using your information to provide you with details about our partner offers. We may also share your information with third parties like FinanceDoneRight, EssentialHealthInfo, SeniorBeginnings, GreaterTrip, and FindPrimeJobs, who may send you details of their products, services and offers. We and our <button id="openPartner" class="underline" @click.prevent="viewPartners">third party partners</button> may contact you by mail, telephone - using audio dialing technology or pre-recorded messages to your phone number provided including wireless numbers you are not required to give consent as a condition of service and you can opt out at any time - email or SMS (text messages) even if you are on a DNC list (either local, state, or federal.) If you would like to know more details about how we deal with your information, you can find our privacy notice at <a href="https://www.reservetechinc.com/privacypolicy.php" target="_blank">Privacy Policy</a>.
                    </label>
                  </small>
                  <br /><br />
                  <small>
                    This is a solicitation for insurance. - <a class="underline" href="https://medicare.essentialhealthinfo.com">https://medicare.essentialhealthinfo.com</a>
                    <br /><br />
                    Participating sales agencies represent Medicare Advantage [HMO, PPO, PFFS, and PDP] organizations that are contracted with Medicare. Enrollment depends on the plan’s contract renewal.
                    <br /><br />
                    <a class="underline" href="https://medicare.essentialhealthinfo.com">https://medicare.essentialhealthinfo.com</a>:<br />
                    We do not offer every plan available in your area. Any information we provide is limited to those plans we do offer in your area. Please contact Medicare.gov or 1–800–MEDICARE (24 hours a day/7 days a week) to get information on all of your options.
                  </small>

                </div>
              </div>

              <input id="ip_address" name="ip_address" type="hidden" v-model="ipAddress" />
              <input id="consent" type="hidden" name="consent" value="By signing up to this EssentialHealth property, you agree to us using your information to provide you with details about our partner offers. We may also share your information with third parties like FinanceDoneRight, EssentialHealthInfo, SeniorBeginnings, GreaterTrip, and FindPrimeJobs, who may send you details of their products, services and offers. We and our third party partners may contact you by mail, telephone - using audio dialing technology or pre-recorded messages to your phone number provided including wireless numbers you are not required to give consent as a condition of service and you can opt out at any time - email or SMS (text messages) even if you are on a DNC list (either local, state, or federal.) If you would like to know more details about how we deal with your information, you can find our privacy notice at Privacy Policy.">
              <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
            </form>
          </div>
        </div>
      </div>
    </div>

    <TransitionRoot as="template" :show="open">
      <Dialog as="div" static class="fixed inset-0 z-10 overflow-y-auto" @close="open = false" :open="open">
        <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <iframe class="w-full" name="{}" src="https://api.leadreserve.com/v1/offer-path/publisher/1" style="border:unset;width:100%;height:725px;overflow:hidden!important;"></iframe>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },

  props: {
    creativeImage: {
      type: String,
    },
    creativeHeading: {
      type: String,
    },
    creativeText: {
      type: String,
    },
    creativeTcpa: {
      type: String,
    },
  },

  computed: {
    formattedCreativeHeading () {
      if (this.creativeHeading) {
        return this.creativeHeading.replace(/<\/?[^>]+>/gi, " ").replace(/(&nbsp;)*/g, '');
      }

      return 'Enter your information to register';
    }
  },

  created () {
    fetch(`${process.env.VUE_APP_API_URL}/ip`).then(response => response.text()).then(ip => {
      this.ipAddress = ip
    })
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      phone: '',
      dob: '',
      ipAddress: '',
      consent: ''
    }
  },

  methods: {
    viewPartners() {
      this.$emit("viewPartners", true);
    },
    formIsValid () {
      return this.firstName !== '' &&
        this.lastName !== '' &&
        this.email !== '' &&
        this.address !== '' &&
        this.city !== '' &&
        this.state !== '' &&
        this.zipCode !== '' &&
        this.phone !== '' &&
        this.dob !== ''
    },

    submit () {
      if (this.formIsValid()) {
        this.open = true
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.address = ''
        this.city = ''
        this.state = ''
        this.zipCode = ''
        this.phone = ''
        this.dob = ''

        fetch('https://leadreserve.com/api/consume', {
          method: 'POST',
          body: new FormData(document.querySelector('form')),
        })
      }
    }
  },

  setup () {
    const open = ref(false)

    return {
      open,
    }
  },
}
</script>
