<template>
  <section class="bg-red-600 py-10">
    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div class="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
        <div class="relative flex flex-col items-center p-6 bg-white shadow-xl">
          <svg class="-mt-1 mb-2" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="32" cy="32" r="32" fill="#DC2626" />
              <path d="M32.3053 17.6001C24.2093 17.6001 17.6533 24.0513 17.6533 32.0001C17.6533 39.9489 24.2093 46.4001 32.3053 46.4001C40.416 46.4001 46.9867 39.9489 46.9867 32.0001C46.9867 24.0513 40.416 17.6001 32.3053 17.6001ZM32.32 43.5201C25.8373 43.5201 20.5867 38.3649 20.5867 32.0001C20.5867 25.6353 25.8373 20.4801 32.32 20.4801C38.8027 20.4801 44.0533 25.6353 44.0533 32.0001C44.0533 38.3649 38.8027 43.5201 32.32 43.5201Z" fill="white" />
              <path d="M33.0535 24.7998H30.8535V33.4398L38.5535 37.9758L39.6535 36.2046L33.0535 32.3598V24.7998Z" fill="white" />
          </svg>

          <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">Only Takes a Moment</h4>
          <p class="text-gray-600 text-center">We won't waste your time. Complete the short form and start saving!</p>
        </div>

        <div class="relative flex flex-col items-center p-6 bg-white shadow-xl">
          <svg class="-mt-1 mb-2" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="32" cy="32" r="32" fill="#DC2626" />
              <path d="M41.1198 26.4532H39.6531V23.5199C39.6531 19.4719 36.3678 16.1865 32.3198 16.1865C28.2718 16.1865 24.9864 19.4719 24.9864 23.5199V26.4532H23.5198C21.9064 26.4532 20.5864 27.7732 20.5864 29.3865V44.0532C20.5864 45.6665 21.9064 46.9865 23.5198 46.9865H41.1198C42.7331 46.9865 44.0531 45.6665 44.0531 44.0532V29.3865C44.0531 27.7732 42.7331 26.4532 41.1198 26.4532ZM32.3198 39.6532C30.7064 39.6532 29.3864 38.3332 29.3864 36.7199C29.3864 35.1065 30.7064 33.7865 32.3198 33.7865C33.9331 33.7865 35.2531 35.1065 35.2531 36.7199C35.2531 38.3332 33.9331 39.6532 32.3198 39.6532ZM36.8664 26.4532H27.7731V23.5199C27.7731 21.0119 29.8118 18.9732 32.3198 18.9732C34.8278 18.9732 36.8664 21.0119 36.8664 23.5199V26.4532Z" fill="white" />
          </svg>

          <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">Your Info is Safe</h4>
          <p class="text-gray-600 text-center">We don't store your personal information or data.</p>
        </div>

        <div class="relative flex flex-col items-center p-6 bg-white shadow-xl">
          <svg class="-mt-1 mb-2" width="64" height="64" viewBox="0 0 64 64" fill="none"  xmlns="http://www.w3.org/2000/svg">
              <circle cx="32" cy="32" r="32" fill="#DC2626" />
              <path d="M40.5702 16.6401C38.6601 16.6401 36.9089 17.2454 35.3654 18.4392C33.8855 19.5837 32.9003 21.0414 32.3202 22.1014C31.7402 21.0413 30.7549 19.5837 29.2751 18.4392C27.7315 17.2454 25.9803 16.6401 24.0702 16.6401C18.7399 16.6401 14.7202 21.0001 14.7202 26.7817C14.7202 33.0279 19.735 37.3015 27.3268 43.7711C28.616 44.8698 30.0773 46.1151 31.5961 47.4433C31.7963 47.6186 32.0535 47.7151 32.3202 47.7151C32.587 47.7151 32.8441 47.6186 33.0443 47.4434C34.5633 46.115 36.0245 44.8697 37.3144 43.7704C44.9054 37.3015 49.9202 33.0279 49.9202 26.7817C49.9202 21.0001 45.9005 16.6401 40.5702 16.6401Z" fill="white" />
          </svg>

          <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">Won't Hurt Your Credit</h4>
          <p class="text-gray-600 text-center">EssentialHealthInfo will not negatively affect your credit.</p>
        </div>
      </div>
    </div>
  </section>
</template>