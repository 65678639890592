<template>
  <section class="bg-cover bg-center" :style="`background-image: url(${ require('../images/background.jpg') })`">
    <div class="max-w-3xl mx-auto text-center py-20">
      <h2 class="font-sans text-3xl font-bold mb-4 text-blue-800">EssentialHealthInfo</h2>
      <p class="text-xl text-gray-600">We are for you in your journey to find a better health.</p>

      <div class="w-full px-3 mb-5">
        <a href="#register">
          <button class="block w-full max-w-xs mt-6 mx-auto bg-white border-2 border-red-600 hover:bg-red-600 hover:text-white focus:bg-red-600 text-red-800 px-3 py-3 font-semibold">
            GET STARTED
          </button>
        </a>
      </div>
    </div>
  </section>
</template>