<template>
  <section class="relative">
    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-20">
        <div class="max-w-3xl mx-auto text-center">
          <h2 class="font-sans text-3xl font-bold mb-4 text-red-600">What is this all about?</h2>
          <p class="text-xl text-gray-600">EssentialHealthInfo gives you the right health coverage with our proprietary tools, articles, and agents here to help you find a plan. Complete the form above, and start saving money instantly!</p>
        </div>
      </div>
    </div>
  </section>
</template>