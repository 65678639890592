<template>
  <page-header />
  <page-form @viewPartners="viewPartners = true" />
  <description />
  <features />
  <call-to-action />
  <page-footer />
  <partner-list v-if="viewPartners" @closePartners="viewPartners = false" />
</template>

<script>
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
import PageForm from '@/components/PageForm'
import Description from '@/components/Description'
import Features from '@/components/Features'
import CallToAction from '@/components/CallToAction'
import PartnerList from "@/components/PartnerList";

export default {
  components: {
    PageHeader,
    PageFooter,
    PageForm,
    Description,
    Features,
    CallToAction,
    PartnerList
  },
  data() {
    return {
      viewPartners: false,
    }
  },
}
</script>
