<template>
    <section
      id="partner"
      class="fixed top-0 w-full h-full bg-black bg-opacity-80"
    >
      <div class="h-full">
        <button
          id="closePartner"
          class="absolute flex justify-center text-sm text-white font-bold bg-red-500 border-1 border-black px-1 my-1 top-5 right-5 rounded-sm"
          @click="closePartners"
        >
          X
        </button>
        <iframe
          class="w-11/12 h-full mx-auto my-10 rounded-xl"
          name="partner list"
          src="https://www.acquirecrowd.com/partners#partnerList"
          style="border: unset; overflow: hidden !important"
        />
      </div>
    </section>
</template>

<script>
export default {
  methods: {
    closePartners() {
      this.$emit("closePartners", true)
    }
  }
}
</script>