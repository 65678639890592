import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import Campaign from '@/views/Campaign'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:uuid',
    name: 'campaign.show',
    component: Campaign
  },
]

const router = createRouter({ history: createWebHistory(), routes })

export default router
